import { Country } from '@/store/modules/country';

import { LatLng } from '@/components/google/maps/types';

import { HasMedia, MediaItem } from '@/models/Media';
import { Space, SpaceApiData } from '@/models/Space';

import { ExactInternal } from '@/helpers/api/companyEnviorment/ExactLinkTypes';
import { ManagementType } from '@/helpers/api/managementEnviorment/CompanyHelper';
import { EnergyLabel } from '@/helpers/energyLabelOptions';
import { toStringSquareMeters } from '@/helpers/javascript/SurfaceHelper';

import i18n from '@/i18n';

import { CompanyApiData } from './Company';
import { HasNotes, Note, Noteable, NoteableModel } from './Noteable';
import { RepairForward } from './RepairForward';
import User from './User';

const $t = i18n.global.t;

type PropertyApiCommon = {
  id: number;
  uuid: string;
  street: string;
  avatar: string;
  btw_applicable: boolean;
  full_address: string;
  postcode: string;
  town: string;
  construction_year: number | null;
  surface: number | null;
  status: PropertyStatus;
  created_at: string;
  repair_flow_id: number | null;
};

export type PropertyStatus = 'active' | 'draft' | 'archived' | 'renovation';

export function renderPropertyStatus(status: PropertyStatus | string): string {
  switch (status) {
    case 'active':
      return $t('models.property.status.active');

    case 'draft':
      return $t('models.property.status.draft');

    case 'archived':
      return $t('models.property.status.archived');

    case 'renovation':
      return $t('models.property.status.renovation');

    default:
      return status;
  }
}

export type VveInterval = 'month' | 'quarter' | 'year';

export function renderVveIntervalText(interval: VveInterval) {
  return $t(`models.property.vveInterval.${interval}`);
}

export type PropertyTaxation = {
  price: number;
  rented_price: number;
  date: string;
};

export type WOZItem = {
  value: number;
  date: string;
};

export type PropertyDetails = {
  bvo: string | null;
  vvo: string | null;
  nen: string | null;

  vve: boolean | null;
  vve_amount: number | null;
  vve_interval: VveInterval | null;
  vve_extra_info: string | null;

  taxations?: PropertyTaxation[];
  woz?: WOZItem[];
};

export enum PropertyType {
  commercial = 'Bedrijfspanden',
  residential = 'Woningen',
  other = 'Overige',
  archived = 'Gearchiveerd'
}

export type PropertyApiData = PropertyApiCommon & {
  active_agreement: boolean;
  almost_expired_agreement: boolean;
  space_count: number;
  property_type: string | null;

  // unknown data types:
  move_in: null;
  move_out: null;
  ended_at: null;
  rent: null;
  total_rent: '0.00';
  spaces_rent: null;
  space_agreement_count: null;
  longitude: string;
  latitude: string;
  avatar: string;
  btw_applicable: boolean;

  // Huurprijscheck data
  has_huurprijscheck_report: boolean;
  synced_with_huurprijscheck: boolean;
  huurprijscheck_report_period: string | null;
  huurprijscheck_overview_url: string | null;
  huurprijscheck_pdf: MediaItem | null;

  company?: CompanyApiData;
  cost_center?: ExactInternal | null;
  exact_project?: ExactInternal | null;
  exact_type: string;
};

export type PropertyApiSingleData = PropertyApiCommon &
  HasNotes & {
    uuid: string;
    house_number: number;
    house_number_addition: string | null;
    energy_label: EnergyLabel;
    energy_index: string | null;
    energy_registration_date: string | null;
    energy_recording_date: string | null;
    energy_expiration_date: string | null;
    energy_certificate_holder: string | null;
    longitude: string | null;
    latitude: string | null;

    purchase_date: string | null;
    purchase_price: number | null;
    renovation_costs: number | null;
    description: string | null;
    detail: string | null;
    avatar: string;
    btw_applicable: boolean;

    default_rent: number;
    default_deposit: number;
    default_service_cost: number;

    country: Country;
    media: MediaItem[];
    property_type: {
      id: number;
      name: string;
    } | null;
    spaces?: SpaceApiData[];
    space_count: number;
    spaces_count: number;
    spaces_exists: boolean;

    wws: number;
    max_rental_price: number;
    synced_with_huurprijscheck: boolean;
    repair_forwards?: RepairForward[];

    property_details: PropertyDetails | null;
    cost_center?: ExactInternal | null;
    exact_project?: ExactInternal | null;
    exact_type: string;

    billing_amount: number | null;
    billing_percentage: string | null;
    management_type: ManagementType | null;
    responsible: User;

    huurprijscheck_pdf: MediaItem | null;
    has_huurprijscheck_report: boolean;
    huurprijscheck_report_period: string | null;
    huurprijscheck_overview_url: string | null;
  };

export type StorePropertyForm = {
  street: string;
  house_number: string;
  house_number_addition: string;
  postcode: string;
  town: string;
  country_id: string;
  construction_year: string;
  surface: number;
  property_type_id: string;
  energy_label: string;
  energy_index: string;
  energy_registration_date: string;
  energy_recording_date: string;
  energy_expiration_date: string;
  energy_certificate_holder: string;
  status: string;

  bagVerblijfsobjectId: string;
  bagPandId: string;
  exact_id: string | undefined;
  exact_type: string | undefined;
  responsible_user_id?: string;
};

export type UpdatePropertyForm = Partial<{
  street: string;
  house_number: string;
  house_number_addition: string;
  postcode: string;
  town: string;
  country_id: string;
  construction_year: string;
  surface: number | string;
  property_type_id: string;
  energy_label: string;
  purchase_price: number;
  status: string;

  btw_applicable: boolean;

  default_rent: number;
  default_deposit: number;
  default_service_cost: number;

  renovation_costs: number;
  purchase_date?: Date;
  description: string;
  detail: string;
  avatar: File | null;
  avatar_delete: boolean;
  btw_number: boolean;
  wws: number;
  max_rental_price: number;

  bvo: string | null;
  vvo: string | null;
  nen: string | null;

  vve: boolean | null;
  vve_amount: number | null;
  vve_interval: VveInterval | null;
  vve_extra_info: string | null;

  repair_flow_id: number | string | null;

  taxations?: PropertyTaxation[];

  woz?: WOZItem[];
  responsible_user_id?: number | string;
}>;

export type KeysOf<T> = Record<keyof T, unknown>;

class PropertyCommon implements KeysOf<PropertyApiCommon> {
  id: number;
  uuid: string;
  street: string;
  avatar: string;
  btw_applicable: boolean;
  full_address: string;
  postcode: string;
  town: string;
  construction_year: number | null;
  surface: number | null;
  created_at: Date;
  repair_flow_id: number | null;
  status: PropertyStatus;

  get name() {
    return '';
  }
  get full_address_city() {
    return `${this.full_address}, ${this.town}`;
  }

  get surfaceFormatted(): string {
    if (this.surface) {
      return toStringSquareMeters(this.surface);
    } else {
      return '-';
    }
  }

  constructor(apiData: PropertyApiCommon) {
    this.id = apiData.id;
    this.uuid = apiData.uuid;
    this.street = apiData.street;
    this.avatar = apiData.avatar;
    this.btw_applicable = apiData.btw_applicable;
    this.full_address = apiData.full_address ?? apiData.street;
    this.postcode = apiData.postcode;
    this.town = apiData.town;
    this.construction_year = apiData.construction_year;
    this.surface = apiData.surface;
    this.status = apiData.status;
    this.created_at = new Date(apiData.created_at);
    this.repair_flow_id = apiData.repair_flow_id ?? null;
  }
}

export class Property extends PropertyCommon implements KeysOf<PropertyApiData> {
  active_agreement: boolean;
  almost_expired_agreement: boolean;
  space_count: number;
  property_type: string | null;
  company: CompanyApiData | undefined;
  repair_availability?: boolean;
  // unknown data types:
  move_in: null;
  move_out: null;
  ended_at: null;
  rent: null;
  total_rent: '0.00';
  spaces_rent: null;
  space_agreement_count: null;
  longitude: string;
  latitude: string;
  cost_center: ExactInternal | null;
  exact_project: ExactInternal | null;
  exact_type: string;
  avatar: string;
  btw_applicable: boolean;
  synced_with_huurprijscheck: boolean;
  has_huurprijscheck_report: boolean;
  huurprijscheck_report_period: string | null;
  huurprijscheck_overview_url: string | null;
  huurprijscheck_pdf: MediaItem | null;

  constructor(apiData: PropertyApiData) {
    super(apiData);
    this.active_agreement = apiData.active_agreement;
    this.almost_expired_agreement = apiData.almost_expired_agreement;
    this.space_count = apiData.space_count;
    this.property_type = apiData.property_type;
    // unknown data types:
    this.move_in = apiData.move_in;
    this.move_out = apiData.move_out;
    this.ended_at = apiData.ended_at;
    this.rent = apiData.rent;
    this.total_rent = apiData.total_rent;
    this.spaces_rent = apiData.spaces_rent;
    this.space_agreement_count = apiData.space_agreement_count;

    this.longitude = apiData.longitude;
    this.latitude = apiData.latitude;
    this.cost_center = apiData.cost_center ?? null;
    this.exact_project = apiData.exact_project ?? null;
    this.exact_type = apiData.exact_type;

    this.company = apiData.company;

    this.avatar = apiData.avatar;
    this.btw_applicable = apiData.btw_applicable;
    this.synced_with_huurprijscheck = apiData.synced_with_huurprijscheck;
    this.has_huurprijscheck_report = apiData.has_huurprijscheck_report;
    this.huurprijscheck_report_period = apiData.huurprijscheck_report_period;
    this.huurprijscheck_overview_url = apiData.huurprijscheck_overview_url;
    this.huurprijscheck_pdf = apiData.huurprijscheck_pdf;
  }

  get rental_status(): 'available' | 'almost_expired' | 'rented' {
    if (!this.active_agreement) {
      return 'available';
    } else if (this.almost_expired_agreement) {
      return 'almost_expired';
    } else {
      return 'rented';
    }
  }
}

export class PropertySingle extends PropertyCommon implements HasMedia, KeysOf<PropertyApiSingleData>, Noteable {
  notes: Note[];
  note_type: NoteableModel;

  uuid: string;
  house_number: number;
  house_number_addition: string | null;
  energy_label: EnergyLabel;
  energy_index: string | null;
  energy_registration_date: string | null;
  energy_recording_date: string | null;
  energy_expiration_date: string | null;
  energy_certificate_holder: string | null;

  longitude: number | null;
  latitude: number | null;

  purchase_date: Date | string;
  purchase_price: number | null;
  description: string | null;
  detail: string | null;
  avatar: string;
  btw_applicable: boolean;

  default_rent: number;
  default_deposit: number;
  default_service_cost: number;

  country: Country;
  media: MediaItem[];
  property_type: {
    id: number;
    name: string;
  } | null;

  spaces: Space[] | undefined;
  space_count: number;
  spaces_count: number;
  spaces_exists: boolean;
  responsible: User;

  repair_forwards: RepairForward[];

  wws: number | null;
  max_rental_price: number | null;
  synced_with_huurprijscheck: boolean;
  renovation_costs: number | null;

  property_details: PropertyDetails | null;
  cost_center: ExactInternal | null | undefined;
  exact_project: ExactInternal | null | undefined;
  exact_type: string;

  billing_amount: number | null;
  billing_percentage: string | null;
  management_type: string | null;

  huurprijscheck_pdf: MediaItem | null;
  has_huurprijscheck_report: boolean;
  huurprijscheck_report_period: string | null;
  huurprijscheck_overview_url: string | null;

  public get location(): LatLng | null {
    if (!this.latitude || !this.longitude) {
      return null;
    }
    return {
      lat: this.latitude,
      lng: this.longitude
    };
  }
  public set location(value: LatLng | null) {
    if (!value) {
      this.longitude = null;
      this.latitude = null;
    } else {
      this.longitude = value.lng;
      this.latitude = value.lat;
    }
  }

  public get bvo(): string | null {
    return this.property_details?.bvo ?? null;
  }
  public get vvo(): string | null {
    return this.property_details?.vvo ?? null;
  }
  public get nen(): string | null {
    return this.property_details?.nen ?? null;
  }
  public get has_vve(): boolean | null {
    return !!this.vve_amount || !!this.vve_interval;
  }
  public get vve_amount(): number | null {
    if (typeof this.property_details?.vve_amount !== 'string') {
      return null;
    }
    return this.property_details.vve_amount;
  }
  public get vve_interval(): VveInterval | null {
    return this.property_details?.vve_interval ?? null;
  }
  public get vve_extra_info(): string | null {
    return this.property_details?.vve_extra_info ?? null;
  }

  constructor(apiData: PropertyApiSingleData) {
    super(apiData);
    this.note_type = 'property';
    this.notes = apiData.notes ?? [];
    this.uuid = apiData.uuid;
    this.house_number = apiData.house_number;
    this.house_number_addition = apiData.house_number_addition;
    this.energy_label = apiData.energy_label;
    this.energy_index = apiData.energy_index;
    this.energy_registration_date = apiData.energy_registration_date;
    this.energy_recording_date = apiData.energy_recording_date;
    this.energy_expiration_date = apiData.energy_expiration_date;
    this.energy_certificate_holder = apiData.energy_certificate_holder;
    this.longitude = apiData.longitude ? parseFloat(apiData.longitude) : null;
    this.latitude = apiData.latitude ? parseFloat(apiData.latitude) : null;

    this.purchase_date = apiData.purchase_date ? new Date(apiData.purchase_date) : '';
    this.purchase_price = apiData.purchase_price;
    this.renovation_costs = apiData.renovation_costs;
    this.description = apiData.description;
    this.detail = apiData.detail;
    this.avatar = apiData.avatar;
    this.btw_applicable = apiData.btw_applicable;

    this.default_rent = apiData.default_rent;
    this.default_deposit = apiData.default_deposit;
    this.default_service_cost = apiData.default_service_cost;

    this.country = apiData.country;
    this.media = apiData.media;
    this.property_type = apiData.property_type;
    this.spaces = apiData.spaces?.map((apiData) => new Space(apiData));
    this.wws = apiData.wws;
    this.max_rental_price = apiData.max_rental_price;
    this.synced_with_huurprijscheck = apiData.synced_with_huurprijscheck;

    this.repair_forwards = apiData.repair_forwards ?? [];

    this.property_details = apiData.property_details;
    this.cost_center = apiData.cost_center;
    this.exact_project = apiData.exact_project;
    this.exact_type = apiData.exact_type;

    this.billing_amount = apiData.billing_amount;
    this.billing_percentage = apiData.billing_percentage;
    this.management_type = apiData.management_type;

    this.space_count = apiData.space_count;
    this.spaces_count = apiData.spaces_count;
    this.spaces_exists = apiData.spaces_exists;
    this.responsible = apiData.responsible;

    this.huurprijscheck_pdf = apiData.huurprijscheck_pdf ?? null;
    this.has_huurprijscheck_report = apiData.has_huurprijscheck_report;
    this.huurprijscheck_report_period = apiData.huurprijscheck_report_period;
    this.huurprijscheck_overview_url = apiData.huurprijscheck_overview_url;
  }
}
export default Property;
